import React, { useEffect, useState } from 'react';
import './App.css';
import { PieChart } from 'react-minimal-pie-chart';
import ArrowImg from './assets/arrow.png';
import LogoImg from './assets/logo.png';
import BasicPackImg from './assets/basic_pack.png';
import LockedPackImg from './assets/locked_pack.png';
import { supabase } from './supabase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBoxesStacked,
    faHammer,
    faHeart,
    faInfoCircle,
    faUserMinus,
    faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { PropagateLoader } from 'react-spinners';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

const MAX_COUNT = 8;
const MIN_COUNT = 2;

const App = () => {
    const [segments, setSegments] = useState([
        { value: 1, color: '#16A085' },
        { value: 1, color: '#2ECC71' },
        { value: 1, color: '#3498DB' },
        { value: 1, color: '#9B59B6' },
        { value: 0, color: '#F1C40F' },
        { value: 0, color: '#E67E22' },
        { value: 0, color: '#E74C3C' },
        { value: 0, color: '#34495E' }
    ]);
    const [count, setCount] = useState(4);
    const [degree, setDegree] = useState(0);
    const [transitionEnabled, setTransitionEnabled] = useState(true);
    const [isSpinning, setIsSpinning] = useState(false);
    const [question, setQuestion] = useState(null);
    const [maxRowCount, setMaxRowCount] = useState(0);
    const [questionId, setQuestionId] = useState(0);
    const [isQuestionLoading, setIsQuestionLoading] = useState(true);
    const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
    const [isQBoxRotated, setIsQBoxRotated] = useState(false);
    const [isBottomSheetPacksOpen, setIsBottomSheetPacksOpen] = useState(false);

    const changeSize = (isAdd) => {
        let newCount = count - 1;
        if (isAdd) newCount = count + 1;

        if (newCount < MIN_COUNT || newCount > MAX_COUNT) return;

        const tempSegments = [...segments];

        for (let i = 0; i < tempSegments.length; i++) {
            if (i < newCount) {
                tempSegments[i].value = 1;
            } else {
                tempSegments[i].value = 0;
            }
        }

        setSegments(tempSegments);
        setCount(newCount);
    };

    const handleSpin = () => {
        setIsSpinning(true);
        const spins = 5;
        const randomDegree = Math.floor(Math.random() * 360);
        const additionalDegree = spins * 360 + randomDegree;
        const newDegree = degree + additionalDegree;

        setDegree(newDegree);

        // Disable the transition and reset the degree to avoid overflow
        setTimeout(() => {
            setTransitionEnabled(false);
            setDegree(newDegree % 360);

            // Re-enable the transition after resetting the degree
            setTimeout(() => {
                setTransitionEnabled(true);
                setIsSpinning(false);
            }, 50); // Short timeout to ensure the transition is re-enabled
        }, 2000); // Match the transition duration
    };

    const getRandomQuestion = async (customCount) => {
        setIsQuestionLoading(true);
        try {
            const maxCount = customCount ? customCount : maxRowCount;

            let randomId = Math.floor(Math.random() * maxCount);
            let retryCount = 0;

            while (randomId === questionId) {
                randomId = Math.floor(Math.random() * maxCount);
                retryCount++;
                if (retryCount > 10) {
                    await initCount();
                    throw new Error('Max retry reached');
                }
            }

            const { data } = await supabase.from('questions').select('*').eq('id', randomId);
            if (data.length > 0) {
                setQuestion(data[0].question);
                setQuestionId(data[0].id);
            }
        } catch (err) {
            console.error(err);
        }
        setIsQuestionLoading(false);
    };

    const initCount = async () => {
        const { count } = await supabase.from('questions').select('*', { count: 'exact', head: true });
        setMaxRowCount(count);
        getRandomQuestion(count);
    };

    const preLoadImages = () => {
        const imageList = [LogoImg, ArrowImg, BasicPackImg, LockedPackImg];
        imageList.forEach((image) => {
            new Image().src = image;
        });
    };

    useEffect(() => {
        initCount();
        preLoadImages();
    }, []);

    return (
        <div className="App">
            <BottomSheet open={isBottomSheetOpen} onDismiss={() => setIsBottomSheetOpen(false)}>
                <div className="bottomSheet">
                    <div className="infoContainer">
                        <strong>Welcome, Strangers!</strong>
                        <p>
                            Break the ice and spark joy with New Convos, packed with fun questions that turns strangers
                            into friends! Here's how:
                        </p>
                        <ol>
                            <li>Start with counting how many players are participating</li>
                            <li>
                                Match the number of segments to the number of players by clicking the add and remove
                                player buttons <FontAwesomeIcon icon={faUserPlus} />{' '}
                                <FontAwesomeIcon icon={faUserMinus} />
                            </li>
                            <li>
                                Place your phone on a flat surface and gather around. Assign each color to the players
                            </li>
                            <li>
                                Click the "New Convo" button to generate a new topic and whichever segment gets picked
                                by the arrow has to answer first
                            </li>
                        </ol>
                        <p>Enjoy your day, Hope you guys have meaningful convos!</p>

                        <div className="madebyContainer">
                            <p>
                                <FontAwesomeIcon icon={faHammer} size="xs" />
                                {'  '}with{'  '}
                                <FontAwesomeIcon icon={faHeart} size="xs" />
                                {'  '}by{' '}
                                <a href="https://kevingo.me" target="_blank" rel="noreferrer">
                                    kevingo
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </BottomSheet>
            <BottomSheet open={isBottomSheetPacksOpen} onDismiss={() => setIsBottomSheetPacksOpen(false)}>
                <div className="bottomSheet">
                    <div className="infoContainer">
                        <strong>Convo Packs</strong>
                        <div className="packOuterContainer">
                            <div className="packContainer">
                                <img src={BasicPackImg} width={100} alt="basic-pack" className="imageSelected" />
                                <p>Basic Pack</p>
                            </div>
                            <div className="packContainer">
                                <img src={LockedPackImg} width={100} alt="locked-pack" />
                                <p>Coming Soon</p>
                            </div>
                        </div>
                    </div>
                </div>
            </BottomSheet>
            <div className="floatContainerTop">
                <img src={LogoImg} alt="logo" height={40} />
                <div
                    className="questionBox"
                    style={{ transform: `rotate(${isQBoxRotated ? '180deg' : '0deg'})` }}
                    onClick={() => setIsQBoxRotated(!isQBoxRotated)}
                >
                    {isQuestionLoading ? <PropagateLoader className="loader" color="#34495E" /> : <p>{question}</p>}
                </div>
            </div>
            <div className="floatContainerBottom">
                <button
                    onClick={() => {
                        getRandomQuestion();
                        handleSpin();
                    }}
                    disabled={isSpinning}
                >
                    NEW CONVO
                </button>
                <div>
                    <button onClick={() => changeSize(false)}>
                        <FontAwesomeIcon icon={faUserMinus} />
                    </button>
                    <button onClick={() => changeSize(true)}>
                        <FontAwesomeIcon icon={faUserPlus} />
                    </button>
                    <button onClick={() => setIsBottomSheetPacksOpen(true)}>
                        <FontAwesomeIcon icon={faBoxesStacked} />
                    </button>
                    <button onClick={() => setIsBottomSheetOpen(true)}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                    </button>
                </div>
            </div>
            <div className="floatContainer">
                <img
                    src={ArrowImg}
                    alt="arrow"
                    width={100}
                    height={100}
                    style={{
                        transform: `rotate(${degree}deg)`,
                        transition: transitionEnabled ? 'transform 2s cubic-bezier(0.16, 1, 0.3, 1)' : 'none'
                    }}
                    onClick={isSpinning ? null : handleSpin}
                />
            </div>
            <PieChart data={segments} radius={1000} />
        </div>
    );
};

export default App;
